import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import Banner from '../components/banner';
import HeadingBlock from '../components/headingBlock';
import SEO from '../components/seo';
import Welcome from '../components/welcome';
import ExternalLink from '../components/externalLink';

import CollaboratorSEOImage from '../assets/images/banner-collaborators.jpg';
import MaternalWellBeingIndexImg from '../assets/images/maternal-wellbeing-index.jpg';
import AMCHPlogo from '../assets/images/AMCHP-logo.png';
import NHSAlogo from '../assets/images/NHSA-logo.png';
import NBEClogo from '../assets/images/NBEC-logo.png';
import ariadnelogo from '../assets/images/ariadne-logo.png';

class Collaborators extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query CollaboratorMeta {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Layout page='collaborators'>
              <SEO
                title='Collaborators'
                description='National collaborators are critical to the success and sustainability of the community-led solutions that drive the Safer Childbirth Cities initiative.'
                image={CollaboratorSEOImage}
                keywords={[``]}
              />
              <Banner
                title='Collaborators'
                subTitle='National collaborators are critical to the success and sustainability of the community-led solutions that drive the Safer Childbirth Cities initiative.'
                titleColor='#FFFFFF'
                subTitleColor='#FFFFFF'
                hasArrow={false}
              />
              <HeadingBlock
                image={CollaboratorSEOImage}
                title='Community of Practice'
                reverse={true}
                height='600px'
              >
                <div className='arrow-down blue'></div>
              </HeadingBlock>
              <section className='darkBlueColorBkgd'>
                <div className='spacer30'></div>
                <Container>
                  <Row>
                    <Col xs='12' sm='12' md='6' lg='6'>
                      <p className='whiteColor'>
                        The Safer Childbirth Cities Community of Practice, led
                        by the Association for Maternal and Child Health
                        Programs (AMCHP), aims to support grantees individually
                        and collectively by providing a forum for grantees to
                        learn from each other and strengthen their capacity to
                        undertake activities such as coalition building,
                        stakeholder engagement, evaluation and sustainability.
                        In forming the Community of Practice, AMCHP has
                        partnered with the National Healthy Start Association
                        (NHSA), the membership organization for over 100
                        federally funded Healthy Start programs situated in the
                        neighborhoods of our nation’s poorest cities and the
                        only decades-old membership organization uniting
                        community-driven programs aimed at improving infant and
                        maternal health in communities of color.
                      </p>
                    </Col>
                    <Col xs='12' sm='12' md='6' lg='6'>
                      <p className='whiteColor'>
                        Together, AMCHP and NHSA are supporting
                        capacity-building across grantees and with their
                        permission and trust and celebrating learnings and
                        innovations as grantees build safer and more equitable
                        communities to give birth. Through the community of
                        practice, the National Birth Equity Collaborative (NBEC)
                        supported efforts to better understand how grantees
                        measure success, evaluate programs and report impact.
                      </p>
                    </Col>
                  </Row>
                </Container>
                <div className='spacer60'></div>
                <div className='arrow-down alternate'></div>
              </section>
              <section>
                <Container>
                  <Row>
                    <Col xs='12' sm='12' md='3' lg='3'>
                      <div className='spacer100'></div>
                      <img
                        className='img-responsive'
                        src={AMCHPlogo}
                        alt='AMCHP'
                      />
                    </Col>
                    <Col xs='12' sm='12' md='9' lg='9'>
                      <div className='spacer60'></div>
                      <h2 className='blueColor'>About AMCHP</h2>
                      <p>
                        AMCHP is the national membership association of state
                        maternal and child health leaders. As an advocate,
                        resource and partner, AMCHP’s mission is to protect and
                        promote the optimal health of women, children, and
                        families. AMCHP envisions a nation that values and
                        invests in the health and well-being of all women,
                        children, families, and communities. AMCHP’s strategic
                        area of{' '}
                        <ExternalLink href='http://www.amchp.org/AboutAMCHP/About/Pages/AMCHPMission.aspx'>
                          <span class='ext-link'>
                            Chasing Zero Maternal and Infant Deaths
                          </span>{' '}
                        </ExternalLink>
                        focuses on four pillars: Support maternal mortality
                        reviews and fetal and infant mortality reviews and equip
                        members to act; Identify what’s working in women’s and
                        infant health and scale up effective practices; Promote
                        woman-centered, community-driven, respectful, and
                        equitable care; and Support and defend programs and
                        policies that reinforce the mother-baby dyad and empower
                        families and communities to thrive. For more
                        information, visit{' '}
                        <ExternalLink href='http://www.amchp.org/'>
                          <span class='ext-link'>www.amchp.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer20'></div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' sm='12' md='3' lg='3'>
                      <div className='spacer50'></div>
                      <img
                        className='img-responsive'
                        src={NHSAlogo}
                        alt='National Healthy Start Association'
                      />
                    </Col>
                    <Col xs='12' sm='12' md='9' lg='9'>
                      <div className='spacer20'></div>
                      <h2 className='blueColor'>
                        About National Healthy Start Association
                      </h2>
                      <p>
                        The National Healthy Start Association (NHSA) is
                        committed to improving birth outcomes and health
                        disparities that exist within communities of color
                        throughout the United States. As the membership
                        association for the federal Healthy Start programs, NHSA
                        promotes the development of community-based maternal and
                        child health programs, particularly those addressing the
                        issues of maternal and infant mortality, low birth
                        weight and racial disparities in perinatal outcomes. For
                        more information, visit{' '}
                        <ExternalLink href='http://www.nationalhealthystart.org/'>
                          <span class='ext-link'>
                            www.nationalhealthystart.org
                          </span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer20'></div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' sm='12' md='3' lg='3'>
                      <div className='spacer50'></div>
                      <img
                        className='img-responsive'
                        src={NBEClogo}
                        alt='National Birth Equity Collaborative'
                      />
                    </Col>
                    <Col xs='12' sm='12' md='9' lg='9'>
                      <div className='spacer20'></div>
                      <h2 className='blueColor'>
                        About National Birth Equity Collaborative
                      </h2>
                      <p>
                        The National Birth Equity Collaborative (NBEC) creates
                        solutions that optimize Black maternal and infant health
                        through training, policy advocacy, research and
                        community-centered collaboration. NBEC activates
                        community power and equips health providers with tools
                        that will catalyze action to change outcomes where there
                        are racial and reproductive justice needs. For more
                        information, visit{' '}
                        <ExternalLink href='http://www.birthequity.org/'>
                          <span class='ext-link'>www.birthequity.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer60'></div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <HeadingBlock
                image={MaternalWellBeingIndexImg}
                title='Maternal Wellbeing City Dashboard'
                reverse={true}
                height='600px'
              />
              <section className='darkBlueColorBkgd'>
                <div className='spacer30'></div>
                <Container>
                  <Row>
                    <Col xs='12' sm='12' md='12' lg='12'>
                      <p className='whiteColor'>
                        For the Safer Childbirth Cities initiative, Ariadne Labs
                        launched a tool - the Maternal Wellbeing City Dashboard
                        – which local governments and community partners can use
                        to measure and improve maternal health using the best
                        available data. The tool provides a focused, visual
                        display of the most important information to help
                        maternal health advocates obtain accountability for
                        results and track progress toward communities that
                        foster the wellbeing of birthing people.
                      </p>
                      <p className='whiteColor'>
                        <ExternalLink href='https://www.ariadnelabs.org/wp-content/uploads/2021/07/Cities-White-Paper-Fostering-Livable-Communities.pdf'>
                          <span class='ext-link'>Learn more</span>
                        </ExternalLink>{' '}
                        about the design and testing of the Maternal Wellbeing
                        City Dashboard.{' '}
                      </p>
                    </Col>
                  </Row>
                </Container>
                <div className='spacer60'></div>
                <div className='arrow-down alternate'></div>
              </section>
              <section>
                <Container>
                  <Row>
                    <Col xs='12' sm='12' md='3' lg='3'>
                      <div className='spacer100'></div>
                      <img
                        className='img-responsive'
                        src={ariadnelogo}
                        alt='Ariadne Labs'
                      />
                    </Col>
                    <Col xs='12' sm='12' md='9' lg='9'>
                      <div className='spacer60'></div>
                      <h2 className='blueColor'>About Ariadne Labs</h2>
                      <p>
                        Ariadne Labs is a joint center for health systems
                        innovation at Brigham and Women’s Hospital and the
                        Harvard T.H. Chan School of Public Health. Ariadne Labs
                        develops simple, scalable solutions that dramatically
                        improve the delivery of health care at critical moments
                        to save lives and reduce suffering. Ariadne Labs’ vision
                        is for health systems to deliver the best possible care
                        for every patient, everywhere, every time. Within
                        Ariadne Labs, the Delivery Decisions Initiative (DDI)
                        works to transform childbirth in the U.S. in partnership
                        with diverse stakeholders by developing data-driven
                        solutions that empower birthing people and redefine
                        standards of care. DDI centers pregnant and birthing
                        people in their innovation efforts, prioritizing
                        dignity, safety and equity for new and growing families.
                        For more information, visit{' '}
                        <ExternalLink href='http://www.ariadnelabs.org'>
                          <span class='ext-link'>www.ariadnelabs.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer60'></div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Welcome
                siteTitle={data.site.siteMetadata.title}
                classes='blueColorBkgd'
              />
            </Layout>
          </>
        )}
      />
    );
  }
}

export default Collaborators;
